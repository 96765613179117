<template>
  <b-modal 
    id="manage-layouts-modal" 
    centered
    title="Layouts"
    hide-footer size="xl"
  >
    <Table 
      :layouts="layouts"
      @view="viewExpenses"
      @delete="deleteModal"
      withView
      :withDelete="withDelete"
    />
    <ExpensesModal 
      :expenses="layout?.expenses"
    />
  </b-modal> 
</template>

<script>
export default {
  components: {
    Table: () => import('@/components/Tiss/Table/LayoutsTable.vue'),
    ExpensesModal: () => import('@/components/Tiss/Modal/ExpensesModal.vue'),
  },
  props: {
    layouts: {
      type: Array,
      required: true,
    },
    withDelete: Boolean
  },
  data() {
    return {
      layout: {
        expenses: [],
      },
    }
  },
  methods: {
    viewExpenses(index) {
      this.layout = this.layouts[index]
      this.$bvModal.show('expenses-modal')
    },
    deleteModal(index) {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">
          <p>Tem certeza que deseja remover o layout <b>${this.layouts[index].name}</b>?</p>
          <p>Essa ação não poderá ser desfeita.</p>
        </div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
       }).then(res => {
        if (res.isConfirmed) {
          if (this.layouts.length === 1)
            this.$bvModal.hide('manage-layouts-modal')
          this.$emit('delete', index)
        }
      })
    },
  },
}
</script>